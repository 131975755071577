



import { Component, Vue } from 'vue-property-decorator'
@Component
export default class Logout extends Vue {
  mounted(){
    console.log("calling...");
    this.$store.commit("ChangeUser",null);
    this.$router.replace({name: 'Login'});
  }
}

